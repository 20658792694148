( function($) {
   $(document).ready( function() {

  //save newsletter START -------------------------------------------------------
  $('#newsletter div.submit').on('click', function(e){ 
     var email = $('#newsletter #newsletter_mail').val();
     var rules = $('#newsletter #nl_checkbox').is(":checked");
console.log(rules);
     if (email == "") {$('.ajax-box').html('<span class="nook">Vyplňte prosím email</span>');}
     else if (rules === false) {$('.ajax-box').html('<span class="nook">Zaškrtněte prosím že souhlasíte s pravidly</span>');}
   
     else {
         $.ajax({  
            url:        '/ajax/saveNewsletterEmail',  
            type:       'POST',   
            dataType:   'json',  
            async:      true,
            data:       jQuery.param({ email:email }),
            
            success: function(data, status) {  
            if (data == "saved") {
                  $(".ajax-box").html('<span class="ok">Email uložen.</span>');
                  $("#newsletter_mail").val("");
               }
            else if (data == "bad_format") {
                  $(".ajax-box").html('<span class="nook">Email nemá správny formát.</span>');
               }
            else if (data == "exists") {
                  $(".ajax-box").html('<span class="nook">Email již existuje.</span>');
            };
             
            },  
            error: function(xhr, textStatus, errorThrown) {  
               console.log('Ajax request failed.');  
               $(".ajax-box").html('<span class="nook">Chyba při ukladání.</span>');
            }  
         });  
     }

  });  
  //save newsletter END -------------------------------------------------------

  
   } );
    } ) ( jQuery );
