import SmoothScroll from "smooth-scroll";
import toastr from "toastr";

//END OF IMPORTS --------------------------------------------------------------------

( function($) {
    $(document).ready( function() {

        //load SMOOTH SCROLL   -------------------------------------------------------        
        var scroll = new SmoothScroll('a.inner-link', {
            speed: 500,
            speedAsDuration: true
        });

        //load TOASTER   ---------------------------------------------------------
        if($('.bs-callout-success').length){
            let title = $(this).find('.fm strong').text();
            let message = $(this).find('.fm p').text();
            toastr.success(message, title, {
              "closeButton": true,
              "newestOnTop": true,
              "progressBar": true,
              "positionClass": "toast-top-center"
            });
          };

        if($('.bs-callout-error').length){
          let title = $(this).find('.fm strong').text();
          let message = $(this).find('.fm p').text();
          toastr.error(message, title, {
            "closeButton": true,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-center"
          });
        };

        
        
    } );
     } ) ( jQuery );
 