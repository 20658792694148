//CSS FE ONLY
require('../../assets/front-assets/css/bootstrap.css');
require('../../assets/front-assets/css/stack-interface.css');
require('../../assets/front-assets/css/socicon.css');
require('../../assets/front-assets/css/lightbox.min.css');
require('../../assets/front-assets/css/flickity.css');
require('../../assets/front-assets/css/iconsmind.css');
require('../../assets/front-assets/css/jquery.steps.css');
require('../../assets/front-assets/css/theme.css');
require('../../assets/front-assets/css/font-roboto.css');
require('../../node_modules/toastr/build/toastr.min.css');
require('../../assets/admin-assets/vendors/css/forms/icheck/icheck.css');
require('../../assets/admin-assets/vendors/css/forms/icheck/custom.css');
require('../../assets/front-assets/css/custom.css');


//JS FE ONLY
require('../../assets/front-assets/js/jquery.init.js');
require('../../assets/front-assets/js/jquery.js');
require('../../assets/front-assets/js/flickity.min.js');
require('../../assets/front-assets/js/easypiechart.min.js');
require('../../assets/front-assets/js/parallax.js');
require('../../assets/front-assets/js/typed.min.js');
require('../../assets/front-assets/js/datepicker.js');
require('../../assets/front-assets/js/isotope.min.js');
require('../../assets/front-assets/js/ytplayer.min.js');
require('../../assets/front-assets/js/lightbox.min.js');
require('../../assets/front-assets/js/granim.min.js');
require('../../assets/front-assets/js/jquery.steps.min.js');
require('../../assets/front-assets/js/countdown.min.js');
require('../../assets/front-assets/js/twitterfetcher.min.js');
require('../../assets/front-assets/js/spectragram.min.js');
require('../../node_modules/smooth-scroll/src/js/smooth-scroll.js');
require('../../assets/admin-assets/vendors/js/forms/icheck/icheck.min.js');

require('../../assets/front-assets/js/scripts.js');
require('../../assets/front-assets/js/ajax.js');
require('../../assets/front-assets/js/custom.js');
